// project import
import dashboard from "./dashboard";
import OfflineInit from "./offlineInit";

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
  items: [dashboard, OfflineInit],
};

export default menuItems;
