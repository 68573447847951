// ==============================|| OVERRIDES - TYPOGRAPHY ||============================== //

export default function Menu() {
  return {
    MuiMenu: {
      styleOverrides: {
        zIndex: 1500,
      },
    },
  };
}
