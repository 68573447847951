import React, { lazy } from "react";
import Loadable from "../../components/Loadable";

const List = Loadable(lazy(() => import("./Sync")));

const routes = [
  {
    path: "sync",
    element: <List />,
  },
];
export default routes;
