const config = {
  key: "id_contenido",
  ListTitle: "Contenido",
  opcion: "contenido",
  newButtonLabel: "Agregar Contenido",
  eliminarTitle: `¿Desea eliminar el contenido {{id}}?`,
  eliminarMessage: null,
  keyEliminar: "id_contenido",
  service: "contenidoServices",
  initialValues: {
    usu_tipo: "",
    nameArchivo: "",
    file: "",
    link: "",
  },
  abmTitle: "Contenido",
};

export default config;
