import config from "../config";

export const opciones = {
  menuVender: 1,
  menuLibros: 2,
  menuColores: 3,
  menuFormulas: -4,
  menuListasPrecio: 5,
  menuRentabilidad: 6,
  menuMateriales: 7,
  menuTipoMaterial: 8,
  menuUsuarios: config.isOffline ? -9 : 9,
  menuTipoUsuario: 10,
  menuRoles: 11,
  menuOpciones: 12,
  menuAuditoria: 107,
  menuSync: 108,
  menuContenido: 112,
  menuActualizarFormula: 115,
  welcome: 999,
  sync: {
    subirDb: 110,
    bajarDb: 109,
  },

  auditorias: {},
  libros: {
    create: 44,
    edit: 45,
    show: 46,
    delete: 47,
  },
  colores: {
    create: 48,
    edit: 49,
    show: 50,
    delete: 51,
    createNoPropios: 104,
  },
  formulas: {
    create: 52,
    edit: 53,
    show: 54,
    delete: 55,
    createNoPropios: 105,
  },
  formulasBase: {
    create: 56,
    edit: 57,
    show: 58,
    delete: 59,
  },
  formulasPigmentos: {
    create: 60,
    edit: 61,
    show: 62,
    delete: 63,
  },
  listaDePrecios: {
    create: 64,
    edit: 65,
    show: 66,
    delete: 67,
  },
  rentabilidad: {
    create: 68,
    edit: 69,
    show: 70,
    delete: 71,
    init: 106,
  },
  materiales: {
    create: 72,
    edit: 73,
    show: 74,
    delete: 75,
  },
  tipoMaterial: {
    create: 76,
    edit: 77,
    show: 78,
    delete: 79,
  },
  tipoUsuariosLibros: {
    create: 80,
    edit: 81,
    show: 82,
    delete: 83,
  },
  usuarios: {
    create: config.isOffline ? -84 : 84,
    edit: config.isOffline ? -85 : 85,
    show: config.isOffline ? -86 : 86,
    delete: config.isOffline ? -87 : 87,
  },
  roles: {
    create: 1,
    edit: 1,
    show: 1,
    delete: 1,
  },

  rolesOpciones: {
    create: 92,
    edit: 93,
    show: 94,
    delete: 95,
  },
  opciones: {
    create: 96,
    edit: 97,
    show: 98,
    delete: 99,
  },
  tipoUsuarios: {
    create: 100,
    edit: 101,
    show: 102,
    delete: 103,
  },
  contenido: {
    create: 113,
    dowload: 112,
    delete: 113,
    redirect: 112,
  },
  formulasSeleccionarLibro: 35,
  seleccionarClienteSap: 38,
  editarRentabilidad: 37,
  editarMateriales: 39,
  editarTipoMateriales: 40,
  editarFormulas: 41,
  editarColores: 42,
  verTodosUsuarios: 114,
};
