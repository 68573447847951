import React, { lazy } from "react";
import Loadable from "../../components/Loadable";

const Recovery = Loadable(lazy(() => import("./Recovery")));

const routes = [
  {
    path: "recovery",
    element: <Recovery />,
  },
];
export default routes;
