const config = {
  key: "rol_id",
  ListTitle: "Roles",
  opcion: "roles",
  newButtonLabel: "Nuevo Rol",
  eliminarTitle: `¿Desea eliminar el rol {{id}}?`,
  eliminarMessage: null,
  keyEliminar: "rol_nombre",
  service: "rolesServices",
  initialValues: {
    rol_nombre: "",
  },
  abmTitle: "Rol",
};

export default config;
