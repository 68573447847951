import React, { lazy } from "react";
import Loadable from "../../components/Loadable";

const SelectSucursal = Loadable(lazy(() => import("../SelectSystem/index")));

const routes = [
  {
    path: "selectSucursal",
    element: <SelectSucursal />,
  },
];
export default routes;
