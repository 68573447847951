import React, { lazy } from "react";
import Loadable from "../../components/Loadable";
import config from "./config";
const List = Loadable(lazy(() => import("./AbmComponents/List")));

const routes = [
  {
    path: "actualizarFormulas",
    element: <List config={config} />,
  },
];
export default routes;
