// material-ui
import { Autocomplete, Box, TextField } from "@mui/material";
import React, { useMemo } from "react";
import { connect } from "react-redux";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";

import Select, { SelectChangeEvent } from "@mui/material/Select";
import api from "../../../../services/api";
import { roles } from "../../../../constants/Roles";
const Search = ({
  sucursales,
  sucursalSeleccionada,
  cambiarSucursal,
  clientes,
  clienteSeleccionado,
  setSucursales,
  changeClient,
  user,
  ...props
}) => {
  const handleChange = (event: SelectChangeEvent) => {
    cambiarSucursal(event.target.value as string);
  };
  const getClientLabel = useMemo(() => {
    const clientLabel = clientes?.find(
      (cliente) => cliente.sap_codigo_cliente === clienteSeleccionado
    );
    return clientLabel?.label ?? null
  }, [clientes, clienteSeleccionado]);
  const handleChangeClient = (value) => {
    api.sapCustomerServices
      .getSucursalesByClient(value)
      .then((r) => {
        if(value){

          changeClient(value as string);
          setSucursales(r[0]);
          cambiarSucursal(r[0][0].sap_codigo_sucursal as string);
        }else {
          changeClient(null);
          setSucursales([]);
          cambiarSucursal(null);
        }
      })
      .catch((e) => {
        console.log(e);
      });
    // cambiarSucursal(event.target.value as string);
  };

  return (
    <Box sx={{ width: "100%", ml: { xs: 0, md: 1 } }}>
      {user.rol_id !== roles.superAdmin &&
      user.rol_id !== roles.admin &&
      user.rol_id !== roles.AdministradorUsuarios ? null : (
        <FormControl style={{ width: "300px", marginLeft: "40px" }}>
          <Autocomplete
            disablePortal
            id="combo-box-demow"
            options={clientes}
            onChange={(e, value: any) => {
              if (value?.sap_codigo_cliente) {
                handleChangeClient(value.sap_codigo_cliente);
              } else {
                handleChangeClient(null)
              };
            }}
            sx={{ width: 300 }}
            value={getClientLabel}
            renderInput={(params) => {
              return <TextField {...params} label="Cliente" />;
            }}
          />

          {/* <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select-cliente"
            disabled={
              user.rol_id !== roles.superAdmin &&
              user.rol_id !== roles.admin &&
              user.rol_id !== roles.AdministradorUsuarios
            }
            value={clienteSeleccionado}
            label="Cliente"
            onChange={handleChangeClient}
          >
            {clientes?.map((s) => (
              <MenuItem
                key={s.sap_codigo_cliente}
                value={s.sap_codigo_cliente.split(";")[0]}
              >
                {s.sap_codigo_cliente}
              </MenuItem>
            ))}
          </Select> */}
        </FormControl>
      )}

      <FormControl style={{ width: "300px", marginLeft: "20px" }}>
        <InputLabel id="demo-simple-select-label">Sucursal</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select-sucursal"
          disabled={
            user.rol_id !== roles.superAdmin &&
            user.rol_id !== roles.admin &&
            user.rol_id !== roles.cliente
          }
          style={{ padding: "6px" }}
          value={sucursalSeleccionada}
          label="sucursal"
          onChange={handleChange}
        >
          {sucursales?.map((s) => (
            <MenuItem key={s.sap_codigo_sucursal} value={s.sap_codigo_sucursal}>
              {s.direccion ?? s.sap_codigo_sucursal}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};
const mapStateToProps = (state) => {
  return {
    sucursales: state.sso.sucursales,
    sucursalSeleccionada: state.sso.sucursalSeleccionada,
    clientes: state.sso.clientes,
    clienteSeleccionado: state.sso.clienteSeleccionado,
    user: state.sso.userProfile,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    cambiarSucursal: (sucursal) => dispatch.sso.changeSucursal(sucursal),
    changeClient: (client) => dispatch.sso.changeClient(client),
    setSucursales: (sucursales) => dispatch.sso.setSucursales(sucursales),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Search);
