const config = {
  key: "id_formula_base",
  ListTitle: "Formulas bases",
  opcion: "formulasBase",
  newButtonLabel: "Nueva formula base",
  eliminarTitle: `¿Desea eliminar la formula base {{id}}?`,
  eliminarMessage: null,
  keyEliminar: "id_formula_base",
  service: "baseFormulaServices",
  initialValues: {
    id_formula: 0,
    id_material: 0,
  },
  abmTitle: "Formula base",
};

export default config;
