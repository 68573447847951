import { lazy } from "react";
import React from "react";
// project import
import Loadable from "../components/Loadable";
import MinimalLayout from "../layout/MinimalLayout";
import Welcome from "../pages/FirstInitOffline/Routes";
import SelectSystem from "../pages/SelectSystem/Routes";
import SelectSucursal from "../pages/SelectSucursal/Routes";
import Recovery from "../pages/Recovery/Routes";

import config from "../config";
// render - login
const AuthLogin = Loadable(lazy(() => import("../pages/authentication/Login")));
const Registry = Loadable(
  lazy(() => import("../pages/authentication/Register"))
);
// ==============================|| AUTH ROUTING ||============================== //

const OfflineChilds = [
  ...Welcome,
  ...SelectSystem,
  ...SelectSucursal,
  ...Recovery,
  {
    path: "login",
    index: true,
    element: <AuthLogin />,
  },
  {
    path: "register",
    element: <Registry />,
  },
];
const OnlineChilds = [
  {
    path: "",
    index: true,
    element: <AuthLogin />,
  },
  {
    path: "register",
    element: <Registry />,
  },
];

const LoginRoutes = {
  path: "",
  element: <MinimalLayout />,
  children: config.isOffline ? OfflineChilds : OnlineChilds,
};

export default LoginRoutes;
