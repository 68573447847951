import { AxiosInstance } from "axios";
import { userTypesIds } from "../constants/userTypes";

const accountServices = (instance: AxiosInstance) => {
  const list = (roles, usuarioTipo, sapCliente?, sapSucursal?) => {
    //Si el usuario es administrador muestra todos los usuarios
    const userType =
      usuarioTipo === userTypesIds["Administrador"]
        ? Object.values(userTypesIds)
            .filter((value) => typeof value === "number")
            .join(",")
        : usuarioTipo;
    let params = `&usu_tipo=${userType}&perPage=100`;
    if (sapCliente) {
      params += `&sapCliente=${sapCliente}`;
    }
    if (sapCliente) {
      params += `&sapSucursal=${sapSucursal}`;
    }
    return instance
      .get(
        `/account?include=userType,userRol&roles=${roles?.join(",")}${params}`
      )
      .then((response: any) => {
        const { data, ...rest } = response.data.result;
        return [data, rest];
      });
  };
  const update = (id, values) => instance.put("/account/" + id, values);
  const del = (id) => instance.delete("/account/" + id);
  const show = (id) =>
    instance.get("/account/" + id).then((response: any) => {
      const { result, ...rest } = response.data;
      return [result, rest];
    });
  const create = (values) => instance.post("/account/signin", values);
  const rolesOptions = () =>
    instance.get("/roles").then((response) => {
      const { result, ...rest } = response.data;
      return [result, rest];
    });
  const userTypesOptions = () =>
    instance.get("/userType").then((response) => {
      const { result, ...rest } = response.data;
      return [result, rest];
    });
  const getDbStatus = () =>
    instance.get("/auth/db-status").then((response) => {
      const { result, ...rest } = response.data;
      return [result, rest];
    });
  const getPlatforms = () =>
    instance.get("/auth/platforms").then((response) => {
      const { result, ...rest } = response.data;
      return [result, rest];
    });
  const setAppSucursal = (sucursal, usu_id, sapCliente) =>
    instance
      .patch("/account/sucursal", {
        sap_codigo_sucursal: sucursal,
        usu_id: usu_id,
        sap_codigo_cliente: sapCliente,
      })
      .then((response) => {
        const { result, ...rest } = response.data;
        return [result, rest];
      });
  const changeAllPass = (pass) =>
    instance.put("/account/data/modify", { usu_clave: pass });

  return {
    list,
    update,
    del,
    show,
    create,
    rolesOptions,
    userTypesOptions,
    getDbStatus,
    getPlatforms,
    setAppSucursal,
    changeAllPass,
  };
};
export default accountServices;
