import PropTypes from "prop-types";
import React from "react";
// material-ui
import { useTheme } from "@mui/material/styles";
import { Stack, Chip } from "@mui/material";

// project import
import DrawerHeaderStyled from "./DrawerHeaderStyled";
import Logo from "../../../../components/Logo";

// ==============================|| DRAWER HEADER ||============================== //

const DrawerHeader = ({ open }: any) => {
  const theme = useTheme();

  return (
    //@ts-ignore
    <DrawerHeaderStyled theme={theme} open={open} className='d-flex justify-content-center'>
      <Stack direction="row" spacing={1} alignItems="center">
        <Logo open={open} />
        {open && (
          <Chip
            className={"versionado"}
            style={{ display: "none" }}
            label={process.env.REACT_APP_VERSION}
            size="small"
            sx={{
              height: 16,
              "& .MuiChip-label": { fontSize: "0.625rem", py: 0.25 },
            }}
            component="a"
            href=""
            target="_blank"
            clickable
          />
        )}
      </Stack>
    </DrawerHeaderStyled>
  );
};

DrawerHeader.propTypes = {
  open: PropTypes.bool,
};

export default DrawerHeader;
