// material-ui
import { Box, Button, useMediaQuery } from "@mui/material";
import React from "react";
import config from "../../../../config";

// project import
import Search from "./Search";
import Profile from "./Profile";
import MobileSection from "./MobileSection";
import { useNavigate } from "react-router";

// =============================|| HEADER - CONTENT ||============================== //

const HeaderContent = () => {
  const navigate = useNavigate();
  const matchesXs = useMediaQuery((theme: any) => theme.breakpoints.down("md"));

  return (
    <>
      {!matchesXs && <Search />}
      {matchesXs && <Box sx={{ width: "100%", ml: 1 }} />}
      {config.isOffline && (
        <Button
          style={{ whiteSpace: "nowrap", width: "230px" }}
          variant="text"
          onClick={() => {
            navigate("../");
          }}
        >
          Cambiar de sistema
        </Button>
      )}
      {!matchesXs && <Profile />}

      {matchesXs && <MobileSection />}
    </>
  );
};

export default HeaderContent;
