import { books } from "../../constants/books";

const config = {
  key: "id_formula",
  ListTitle: "Formulas",
  opcion: "formulas",
  newButtonLabel: "Nueva formula",
  eliminarTitle: `¿Desea eliminar la formula  {{id}}?`,
  eliminarMessage: null,
  keyEliminar: "id_formula",
  service: "formulasServices",
  initialValues: {
    id_libro: books.PROPIOS,
    id_color: 0,
    id_tipo_base: 0,
    id_tipo_subproducto: 0,
    for_cantidad_base: 0,
    sap_codigo_cliente: 0,
  },
  abmTitle: "Formula",
};

export default config;
