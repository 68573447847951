// assets
import {
  HomeOutlined,
  UserOutlined,
  BgColorsOutlined,
  FormatPainterOutlined,
  CloudSyncOutlined,
  DollarCircleOutlined,
  FunctionOutlined,
  BookOutlined,
  ContactsOutlined,
  MenuOutlined,
  BuildOutlined,
  ShoppingCartOutlined,
  BarChartOutlined,
} from "@ant-design/icons";
import { opciones } from "../constants/opciones";
import { roles, gruposDeRoles } from "../constants/Roles";
// icons

const icons = {
  HomeOutlined,
  UserOutlined,
  BgColorsOutlined,
  FormatPainterOutlined,
  CloudSyncOutlined,
  FunctionOutlined,
  DollarCircleOutlined,
  BookOutlined,
  ContactsOutlined,
  MenuOutlined,
  BuildOutlined,
  ShoppingCartOutlined,
  BarChartOutlined,
};

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const OfflineInit = {
  id: "group-dashboard",
  title: "",
  type: "group",
  children: [
    {
      id: opciones.welcome,
      title: "welcome",
      type: "item",
      url: "/welcome",
      icon: icons.ShoppingCartOutlined,
      breadcrumbs: false,
      admitedRoles: gruposDeRoles.level4,
      optionId: opciones.welcome,
      isWelcomeRoute: true,
    },
  ],
};

export default OfflineInit;
