import { AxiosInstance } from "axios";

const syncServices = (instance: AxiosInstance) => {
  const generateFile = (data = '') =>
    instance.get(`/sync/generate-file${data}`).then((response: any) => {
      const { result, ...rest } = response.data;
      return [result, rest];
    });
  const getFile = (id) =>
    instance.get(`/sync/get-file/${id}`).then((response: any) => {
      const { result, ...rest } = response.data;
      return [result, rest];
    });
  const generateNewsFile = (date, data = '') =>
    instance
      .get("/sync/news/generate-file?dateFrom=" + date + " 00:00:00" + data)
      .then((response: any) => {
        const { result, ...rest } = response.data;
        return [result, rest];
      });
  const generateNewsFileOffline = (date) =>
    instance
      .get("/sync/news/generate-file?dateFrom=" + date + " 00:00:00")
      .then((response: any) => {
        const { result, ...rest } = response.data;
        return [result, rest];
      });
  const getRecoveryFile = (id) =>
    instance.get(`/sync/recovery/get-file/${id}`).then((response: any) => {
      const { result, ...rest } = response.data;
      return [result, rest];
    });
  const getNewsFileOffline = (id) =>
    instance.get(`/sync/news/get-file/${id}`, { responseType: "blob" });
  const getNewsFile = (id) =>
    instance.get(`/sync/news/get-file/${id}`).then((response: any) => {
      const { result, ...rest } = response.data;
      return [result, rest];
    });

  const databaseSync = (formData) =>
    instance
      .post("/sync/data-sync", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response: any) => {
        const { result, ...rest } = response.data;
        return [result, rest];
      });
  const localFirstSync = (formData) =>
    instance
      .post("/sync", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response: any) => {
        const { result, ...rest } = response.data;
        return [result, rest];
      });

  const renewPassword = (formData) =>
    instance
      .post("/account/recovery-password", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response: any) => {
        const { result, ...rest } = response.data;
        return [result, rest];
      });

  const localNewsSync = (formData) =>
    instance
      .post("/sync/news", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response: any) => {
        const { result, ...rest } = response.data;
        return [result, rest];
      });

  const recoverPasswordOffline = (date) =>
    instance
      .put("/account/offline/recovery-password", {})
      .then((response: any) => {
        const { result, ...rest } = response.data;
        return [result, rest];
      });

  return {
    generateFile,
    getFile,
    recoverPasswordOffline,
    generateNewsFile,
    getNewsFile,
    databaseSync,
    localFirstSync,
    localNewsSync,
    generateNewsFileOffline,
    getNewsFileOffline,
    getRecoveryFile,
    renewPassword,
  };
};
export default syncServices;
