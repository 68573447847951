const config = {
  key: "id_tipo_material",
  ListTitle: "Tipos de materiales",
  opcion: "tipoMaterial",
  newButtonLabel: "Nuevo Tipo de material",
  eliminarTitle: `¿Desea eliminar el tipo de material {{id}}?`,
  eliminarMessage: null,
  keyEliminar: "tmat_denom",
  service: "materialTypeServices",
  initialValues: {
    tmat_denom: "",
  },
  abmTitle: "Tipo de material",
};

export default config;
