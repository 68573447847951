import PropTypes from "prop-types";
import { useRef } from "react";
import { connect } from "react-redux";
// material-ui
import React from "react";

import { userProfile } from "../../../../../types/reduxTypes";
import { Box, ButtonBase, IconButton, Stack, Typography } from "@mui/material";

import { LogoutOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router";

// tab panel wrapper
function TabPanel({ children, value, index, ...other }: any) {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`profile-tabpanel-${index}`}
      aria-labelledby={`profile-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

// ==============================|| HEADER CONTENT - PROFILE ||============================== //

const Profile = ({
  userProfile,
  logout,
}: {
  userProfile: userProfile;
  logout: any;
}) => {
  const navigate = useNavigate();
  const handleLogout = async () => {
    logout();
    navigate("/");
  };

  const anchorRef = useRef(null);

  return (
    <Box sx={{ flexShrink: 0, ml: 0.75 }}>
      <ButtonBase
        sx={{
          p: 0.25,
          bgcolor: "transparent",
          borderRadius: 1,
          "&:hover": { bgcolor: "secondary.lighter" },
        }}
        aria-label="open profile"
        ref={anchorRef}
        aria-controls={undefined}
        aria-haspopup="true"
        onClick={() => {}}
      >
        <Stack
          direction="row"
          spacing={2}
          style={{ textAlign: "right" }}
          sx={{ p: 0.5 }}
        >
          <Typography variant="subtitle1">
            {userProfile.usu_mail}
            <br />
            <small>
              Actualización: {new Date().toLocaleDateString()}-{" "}
              {new Date().toLocaleTimeString()}
            </small>
          </Typography>
        </Stack>
      </ButtonBase>
      <IconButton size="large" color="secondary" onClick={handleLogout}>
        <LogoutOutlined />
      </IconButton>
    </Box>
  );
};

const mapState = (state: any) => {
  return {
    userProfile: state.sso.userProfile,
  };
};
const mapDispatch = (dispatch: any) => {
  return {
    logout: () => dispatch.sso.logout(),
  };
};

export default connect(mapState, mapDispatch)(Profile);
