import React, { lazy } from "react";
import Loadable from "../../components/Loadable";

const List = Loadable(lazy(() => import("./AbmComponents/List")));
const Update = Loadable(lazy(() => import("./AbmComponents/Update")));
const Show = Loadable(lazy(() => import("./AbmComponents/Show")));
const Create = Loadable(lazy(() => import("./AbmComponents/Create")));

const routes = [
  {
    path: "users",
    element: <List />,
  },
  {
    path: "users/update/:id",
    element: <Update />,
  },
  {
    path: "users/create",
    element: <Create />,
  },
  {
    path: "users/show/:id",
    element: <Show />,
  },
];
export default routes;
