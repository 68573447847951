import React, { lazy } from "react";
import Loadable from "../../components/Loadable";

const Venta = Loadable(lazy(() => import("./Index")));

const routes = [
  {
    path: "venta",
    element: <Venta />,
  },
  {
    path: "venta/:colorId",
    element: <Venta />,
  },
];
export default routes;
