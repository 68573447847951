const config = {
  key: "id_formula_pigmento",
  ListTitle: "Formulas pigmentos",
  opcion: "formulasPigmentos",
  newButtonLabel: "Nueva formula pigmento",
  eliminarTitle: `¿Desea eliminar la formula pigmento {{id}}?`,
  eliminarMessage: null,
  keyEliminar: "id_formula_pigmento",
  service: "pigmentsFormula",
  initialValues: {
    id_formula: 0,
    mat_codigo: 0,
    forpig_cantidad: 0,
  },
  abmTitle: "Formula pigmento",
};

export default config;
