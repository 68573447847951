const config = {
  key: "opcion_id",
  ListTitle: "Opciones",
  opcion: "opciones",
  newButtonLabel: "Nueva Opción",
  eliminarTitle: `¿Desea eliminar la opción {{id}}?`,
  eliminarMessage: null,
  keyEliminar: "opcion_nombre",
  service: "optionsServices",
  initialValues: {
    opcion_nombre: "",
  },
  abmTitle: "Opción",
};

export default config;
