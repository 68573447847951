// project import
import Navigation from "./Navigation";
import SimpleBar from "../../../../components/third-party/SimpleBar";
import React from "react";
import { variants } from "../../../../constants/variants";
import { VARIANT } from "../../../../config";
// ==============================|| DRAWER CONTENT ||============================== //
const tersuave = require("../../../../assets/images/icons/disal-21.png");
const colorin = require("../../../../assets/images/icons/colorinLong.png");
const industria = require("../../../../assets/images/icons/disal-20.png");

const tersuavesm = require("../../../../assets/images/icons/disal-26.png");
const colorinsm = require("../../../../assets/images/icons/colorinLong.png");
const industriasm = require("../../../../assets/images/icons/disal-27.png");
const logos = {
  [variants.COLORIN]: colorin,
  [variants.TERSUAVE]: tersuave,
  [variants.INDUSTRIA]: industria,
};
const logossm = {
  [variants.COLORIN]: colorinsm,
  [variants.TERSUAVE]: tersuavesm,
  [variants.INDUSTRIA]: industriasm,
};
const DrawerContent = () => (
  <SimpleBar
    sx={{
      "& .simplebar-content": {
        display: "flex",
        flexDirection: "column",
      },
    }}
  >
    <div className="logoEnMenu lg">
      <img src={logos[VARIANT]} alt={VARIANT} />
    </div>
    <div className="logoEnMenu sm">
      <img src={logossm[VARIANT]} alt={VARIANT} />
    </div>
    <Navigation />
  </SimpleBar>
);

export default DrawerContent;
