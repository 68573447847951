const config = {
  key: "id_material",
  ListTitle: "Material",
  opcion: "materiales",
  newButtonLabel: "Nuevo  material",
  eliminarTitle: `¿Desea eliminar el material {{id}}?`,
  eliminarMessage: null,
  keyEliminar: "mat_denom_corta",
  service: "materialServices",
  initialValues: {
    mat_codigo: 0,
    mat_denominacion: "",
    mat_denom_corta: "",
    mat_letra: "",
    id_tipo_material: 0,
    id_tipo_subproducto: 0,
    id_tipo_base: 0,
    mat_volumen: 0,
    mat_peso_especifico: 0,
    mat_observacion: "",
  },
  abmTitle: "Material",
};

export default config;
