const config = {
  key: "id_color",
  ListTitle: "Colores",
  opcion: "colores",
  newButtonLabel: "Nuevo color",
  eliminarTitle: `¿Desea eliminar el color {{id}}?`,
  eliminarMessage: null,
  keyEliminar: "col_denom",
  service: "colorsServices",
  initialValues: {
    col_codigo: "",
    col_denom: "",
    sap_codigo_cliente: "3100193",
  },
  abmTitle: "Color",
};

export default config;
