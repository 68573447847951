const config = {
  key: "id_formula",
  ListTitle: "Actualizar fórmula",
  opcion: "actualizar fórmula",
  newButtonLabel: "Actualizar fórmula",
  service: "actualizarFormulaServices",
  initialValues: {},
};

export default config;
