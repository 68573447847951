import { Outlet } from "react-router-dom";
import React from "react";
// ==============================|| MINIMAL LAYOUT ||============================== //

const MinimalLayout = () => (
  <>
    <Outlet />
  </>
);

export default MinimalLayout;
