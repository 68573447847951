import { AxiosInstance } from "axios";

const contenidoServices = (instance: AxiosInstance) => {
  const list = () =>
    instance.get("/contenido").then((response: any) => {
      const { data, ...rest } = response.data.result;
      return [data, rest];
    });

  const update = (id, values) => instance.put("/contenido/" + id, values);

  const del = (id) => instance.delete("/contenido/" + id);

  const show = (id) =>
    instance.get("/contenido/" + id).then((response: any) => {
      const { result, ...rest } = response.data;
      return [result, rest];
    });

  const create = (values) => instance.post("/contenido", values);

  const usuarios = () =>
    instance.get("/contenido/contenido-tipo-usuario").then((response: any) => {
      const { data, ...rest } = response.data.result;
      return [data, rest];
    });

  const download = (fileObj) => {
    const fileName = fileObj.fileName;
    const encodedFileName = encodeURIComponent(fileName);
    return instance.get(`contenido/download?fileName=${encodedFileName}`).then((response: any) => {
      const { result, ...rest } = response.data;
      return [result, rest];
    });
  }

  return {
    list,
    update,
    del,
    show,
    create,
    usuarios,
    download
  };
};
export default contenidoServices;
