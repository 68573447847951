import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import AuthContext from "../../AuthContext";
import { connect } from "react-redux";
// material-ui
import { useTheme } from "@mui/material/styles";
import { Box, Toolbar, useMediaQuery } from "@mui/material";

// project import
import Drawer from "./Drawer";
import Header from "./Header";
import navigation from "../../menu-items";
import Breadcrumbs from "../../components/@extended/Breadcrumbs";

// types
import { userProfile } from "../../types/reduxTypes";
import { openDrawer } from "../../store/reducers/menu";
import SessionExpire from "../../components/@Ithreex/SessionExpire/SessionExpire";

// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = ({
  token = null,
  userProfile = null,
  menu = [],
}: {
  token?: string | null;
  userProfile?: userProfile | null;
  menu?: [];
}) => {
  const theme = useTheme();
  const matchDownLG = useMediaQuery(theme.breakpoints.down("xl"));
  const dispatch = useDispatch();

  const { drawerOpen } = useSelector((state: any) => state.menu);

  // drawer toggler
  const [open, setOpen] = useState(drawerOpen);
  const handleDrawerToggle = () => {
    setOpen(!open);
    dispatch(openDrawer({ drawerOpen: !open }));
  };

  // set media wise responsive drawer
  useEffect(() => {
    setOpen(!matchDownLG);
    dispatch(openDrawer({ drawerOpen: !matchDownLG }));
  }, [matchDownLG]);

  useEffect(() => {
    if (open !== drawerOpen) setOpen(drawerOpen);
  }, [drawerOpen]);

  return (
    <Box sx={{ display: "flex", width: "100%" }}>
      <div className={open ? "abierto" : "cerrado"}>
        <Header open={open} handleDrawerToggle={handleDrawerToggle} />
        <Drawer open={open} handleDrawerToggle={handleDrawerToggle} />
      </div>
      <Box
        component="main"
        sx={{ width: "100%", flexGrow: 1, p: { xs: 2, sm: 3 } }}
      >
        <Toolbar />
        <Breadcrumbs
          navigation={navigation}
          title
          //@ts-ignore
          titleBottom
          card={false}
          divider={false}
        />
        <AuthContext token={token} role={userProfile?.rol_id} menu={menu}>
          <div className={"applicationTintometrico"}>
            <Outlet />
          </div>
        </AuthContext>
      </Box>
      <SessionExpire />
    </Box>
  );
};
const mapStateToProps = (state: any) => {
  return {
    token: state.sso.token,
    userProfile: state.sso.userProfile,

    menu: state.sso.menu,
  };
};
export default connect(mapStateToProps, null)(MainLayout);
