export enum variants {
  COLORIN = "colorin",
  INDUSTRIA = "industria",
  TERSUAVE = "tersuave",
  HAYCOLOR = "haycolor",
}
export const variantesId = {
  colorin: 2, //: variants.COLORIN,
  industria: 3, // variants.INDUSTRIA,
  tersuave: 4, //: variants.TERSUAVE,
  haycolor: 4,
};

export const colorinColores = {
  main: "#24275C",
  buttons: "#1F408B",
};
export const industriaColores = {
  main: "white",
  buttons: "#1F408B",
};
export const tersuaveColores = {
  main: "white",
  buttons: "#1F408B",
};
