// material-ui
import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";
import React from "react";
// ==============================|| DRAWER HEADER - STYLED ||============================== //
import { VARIANT } from "../../../../config";
import {
  variants,
  colorinColores,
  tersuaveColores,
  industriaColores,
} from "../../../../constants/variants";
const DrawerHeaderStyled = styled(Box, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }: any) => ({
  ...theme.mixins.toolbar,
  display: "flex",
  alignItems: "center",
  background:
    variants.COLORIN === VARIANT
      ? colorinColores.main
      : variants.INDUSTRIA === VARIANT
      ? industriaColores.main
      : tersuaveColores.main,
  justifyContent: open ? "flex-start" : "center",
  paddingLeft: theme.spacing(open ? 3 : 0),
}));

export default DrawerHeaderStyled;
