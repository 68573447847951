import React, { lazy } from "react";
import Loadable from "../../components/Loadable";

const SelectSystem = Loadable(lazy(() => import("./SelectSystem")));

const routes = [
  {
    path: "",
    element: <SelectSystem />,
  },
];
export default routes;
