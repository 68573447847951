import React, { lazy } from "react";
import Loadable from "../../components/Loadable";

const Welcome = Loadable(lazy(() => import("./Welcome")));

const routes = [
  {
    path: "uploadDb",
    element: <Welcome />,
  },
];
export default routes;
