const config = {
  key: "id_material_rentabilidad",
  ListTitle: "Rentabilidad",
  opcion: "rentabilidad",
  newButtonLabel: "Nueva Rentabilidad",
  eliminarTitle: `¿Desea eliminar la rentabilidad {{id}}?`,
  eliminarMessage: null,
  keyEliminar: "id_material_rentabilidad",
  service: "rentabilidadServices",
  createDisabled: false,
  initialValues: {
    sap_codigo_cliente: "",
    cli_organizacion_venta: "",
    cli_canal: "",
    sap_codigo_material: "",
    lp_precio_lista: "",
    lp_descuentos: 0,
    lp_precio_neto: 0,
  },
  abmTitle: "Rentabilidad",
};

export default config;
