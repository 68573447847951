// material-ui
import React from "react";
import { variants } from "../../constants/variants";
import { VARIANT } from "../../config";
const tersuave = require("../../assets/images/icons/disal-24.png");
const tersuavesm = require("../../assets/images/icons/disal-26.png");

const colorin = require("../../assets/images/icons/colorinblanco-06.png");
const colorinsm = require("../../assets/images/icons/colorinblanco-05.png");

const industria = require("../../assets/images/icons/disal-23.png");
const industriasm = require("../../assets/images/icons/disal-27.png");

const Logo = ({ open }) => {
  return (
    <>
      {open ? (
        <h1>
          {VARIANT === variants.TERSUAVE ? (
            <img style={{ height: "27px" }} src={tersuave} />
          ) : VARIANT === variants.COLORIN ? (
            <img style={{ height: "27px" }} src={colorin} />
          ) : VARIANT === variants.INDUSTRIA ? (
            <img style={{ height: "27px" }} src={industria} />
          ) : (
            "Tintométrico"
          )}
        </h1>
      ) : (
        <h1>
          {VARIANT === variants.TERSUAVE ? (
            <img style={{ height: "27px" }} src={tersuavesm} />
          ) : VARIANT === variants.COLORIN ? (
            <img style={{ height: "27px" }} src={colorinsm} />
          ) : VARIANT === variants.INDUSTRIA ? (
            <img style={{ height: "27px" }} src={industriasm} />
          ) : (
            "T"
          )}
        </h1>
      )}
    </>
  );
  // return (
  //   <>
  //     {open ? (
  //       <h1>
  //         {VARIANT === variants.TERSUAVE
  //           ? "Hay color"
  //           : VARIANT === variants.COLORIN
  //           ? "Colorin"
  //           : VARIANT === variants.INDUSTRIA
  //           ? "Industria"
  //           : "Tintométrico"}
  //       </h1>
  //     ) : (
  //       <h1>
  //         {VARIANT === variants.TERSUAVE
  //           ? "HC"
  //           : VARIANT === variants.COLORIN
  //           ? "C"
  //           : VARIANT === variants.INDUSTRIA
  //           ? "I"
  //           : "T"}
  //       </h1>
  //     )}
  //   </>
  // );
  // return (
  //   <>
  //     {open ? (
  //       <h1>
  //         {window.config.VARIANT === variants.TERSUAVE
  //           ? "Hay color"
  //           : window.config.VARIANT === variants.COLORIN
  //           ? "Colorin"
  //           : window.config.VARIANT === variants.INDUSTRIA
  //           ? "Industria"
  //           : "Tintométrico"}
  //       </h1>
  //     ) : (
  //       <h1>
  //         {window.config.VARIANT === variants.TERSUAVE
  //           ? "HC"
  //           : window.config.VARIANT === variants.COLORIN
  //           ? "C"
  //           : window.config.VARIANT === variants.INDUSTRIA
  //           ? "I"
  //           : "T"}
  //       </h1>
  //     )}
  //   </>
  // );
};

export default Logo;
