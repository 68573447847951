const config = {
  key: "id",
  ListTitle: "Auditorias",
  opcion: "auditorias",
  newButtonLabel: "Nueva auditoria",
  eliminarTitle: `¿Desea eliminar la auditoria {{id}}?`,
  eliminarMessage: null,
  keyEliminar: "id",
  service: "auditServices",
  createDisabled: true,
  initialValues: {},
  abmTitle: "Auditoria",
};

export default config;
