const config = {
  key: "id_Lista_Precio",
  ListTitle: "Listas de precios",
  opcion: "listaDePrecios",
  newButtonLabel: "Nuevo Lista",
  eliminarTitle: `¿Desea eliminar la lista {{id}}?`,
  eliminarMessage: null,
  keyEliminar: "id_Lista_Precio",
  service: "listaDePrecios",
  initialValues: {
    sap_codigo_cliente: "",
    cli_organizacion_venta: "",
    cli_canal: "",
    sap_codigo_material: "",
    lp_precio_lista: "",
    lp_descuentos: "",
    lp_precio_neto: "",
  },
  abmTitle: "Lista de precio",
};

export default config;
