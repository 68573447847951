import PropTypes from "prop-types";
import React, { forwardRef } from "react";
import TitleWithBackArrow from "./TitleWithBackArrow";
// material-ui
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";

// project import
import { VARIANT } from "../config";
import { variants } from "../constants/variants";
import Highlighter from "./third-party/Highlighter";
const bg = require("../assets/images/bgcard-28.png");

// header style
const headerSX = (widthAction, directionColumn) => {
  return {
    p: 2.5,
    ...(directionColumn && {
      display: "flex", // Hace que el contenedor actúe como flexbox
      flexDirection: "column", // Coloca elementos en una columna
      alignItems: "flex-start", // Alinea los elementos al principio en el eje principal
      gap: "10px",
    }),
    "& .MuiCardHeader-action": {
      m: "0px auto",
      alignSelf: "end",
      ...(widthAction && { width: widthAction }),
    },
  };
};
// ==============================|| CUSTOM - MAIN CARD ||============================== //

const MainCard = forwardRef(
  (
    {
      border = true,
      boxShadow,
      children,
      content = true,
      contentSX = {},
      darkTitle,
      divider = true,
      elevation,
      secondary,
      shadow,
      sx = {},
      title,
      codeHighlight,
      widthAction = false,
      directionColumn = false,
      ...others
    }: any,
    ref
  ) => {
    const theme: any = useTheme();
    boxShadow = theme.palette.mode === "dark" ? boxShadow || true : boxShadow;

    return (
      <Card
        style={
          VARIANT === variants.TERSUAVE
            ? { backgroundImage: `url(${bg})`, backgroundSize: "cover" }
            : {}
        }
        elevation={elevation || 0}
        ref={ref}
        {...others}
        sx={{
          ...sx,
          border: border ? "1px solid" : "none",
          borderRadius: 2,
          borderColor:
            theme.palette.mode === "dark"
              ? theme.palette.divider
              : theme.palette.grey.A800,
          boxShadow:
            boxShadow && (!border || theme.palette.mode === "dark")
              ? shadow || theme.customShadows.z1
              : "inherit",
          ":hover": {
            boxShadow: boxShadow ? shadow || theme.customShadows.z1 : "inherit",
          },
          "& pre": {
            m: 0,
            p: "16px !important",
            fontFamily: theme.typography.fontFamily,
            fontSize: "0.75rem",
          },
        }}
      >
        {/* card header and action */}
        {!darkTitle && title && (
          <CardHeader
            sx={headerSX(widthAction, directionColumn)}
            titleTypographyProps={{ variant: "subtitle1" }}
            title={<TitleWithBackArrow title={title} />}
            action={secondary}
          />
        )}
        {darkTitle && title && (
          <CardHeader
            sx={headerSX(widthAction, directionColumn)}
            title={
              <TitleWithBackArrow
                title={<Typography variant="h3">{title}</Typography>}
              />
            }
            action={secondary}
          />
        )}

        {/* content & header divider */}
        {title && divider && <Divider />}

        {/* card content */}
        {content && <CardContent sx={contentSX}>{children}</CardContent>}
        {!content && children}

        {/* card footer - clipboard & highlighter  */}
        {codeHighlight && (
          <>
            <Divider sx={{ borderStyle: "dashed" }} />
            {/* @ts-ignore */}
            <Highlighter codeHighlight={codeHighlight} main>
              {children}
            </Highlighter>
          </>
        )}
      </Card>
    );
  }
);

MainCard.propTypes = {
  border: PropTypes.bool,
  boxShadow: PropTypes.bool,
  contentSX: PropTypes.object,
  darkTitle: PropTypes.bool,
  divider: PropTypes.bool,
  elevation: PropTypes.number,
  secondary: PropTypes.node,
  shadow: PropTypes.string,
  sx: PropTypes.object,
  title: PropTypes.string,
  codeHighlight: PropTypes.bool,
  content: PropTypes.bool,
  children: PropTypes.node,
};

export default MainCard;
