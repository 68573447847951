// assets
import {
  BarChartOutlined,
  BgColorsOutlined,
  BookOutlined,
  BuildOutlined,
  ClearOutlined,
  CloudSyncOutlined,
  ContactsOutlined,
  DollarCircleOutlined,
  FormatPainterOutlined,
  FunctionOutlined,
  HomeOutlined,
  MenuOutlined,
  ShoppingCartOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { gruposDeRoles } from "../constants/Roles";
import { opciones } from "../constants/opciones";
// icons

const icons = {
  HomeOutlined,
  UserOutlined,
  BgColorsOutlined,
  FormatPainterOutlined,
  CloudSyncOutlined,
  FunctionOutlined,
  DollarCircleOutlined,
  BookOutlined,
  ContactsOutlined,
  MenuOutlined,
  BuildOutlined,
  ShoppingCartOutlined,
  BarChartOutlined,
  ClearOutlined,
};

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const dashboard = {
  id: "group-dashboar2d",
  title: "",
  type: "group",
  children: [
    {
      id: opciones.menuVender,
      title: "Consulta fórmula",
      type: "item",
      url: "/venta",
      icon: icons.ShoppingCartOutlined,
      breadcrumbs: false,
      admitedRoles: gruposDeRoles.level4,
      optionId: opciones.menuVender,
      isWelcomeRoute: false,
    },
    {
      id: opciones.menuLibros,
      title: "Libros",
      type: "item",
      url: "/libros",
      icon: icons.BookOutlined,
      breadcrumbs: false,
      admitedRoles: gruposDeRoles.level4,
      optionId: opciones.menuLibros,
      isWelcomeRoute: false,
    },
    {
      id: opciones.menuColores,
      title: "Colores",
      type: "item",
      url: "/colores",
      icon: icons.BgColorsOutlined,
      breadcrumbs: false,
      admitedRoles: gruposDeRoles.level4,
      optionId: opciones.menuColores,
      isWelcomeRoute: false,
    },

    {
      id: opciones.menuFormulas,
      title: "Formulas",
      type: "item",
      url: "/formulas",
      icon: icons.FunctionOutlined,
      breadcrumbs: false,
      admitedRoles: gruposDeRoles.level4,
      optionId: opciones.menuFormulas,
      isWelcomeRoute: false,
    },
    {
      id: opciones.menuActualizarFormula,
      title: "Actualizar fórmula",
      type: "item",
      url: "/actualizarFormulas",
      icon: icons.ClearOutlined,
      breadcrumbs: false,
      admitedRoles: gruposDeRoles.level4,
      optionId: opciones.menuActualizarFormula,
      isWelcomeRoute: false,
    },
    {
      id: opciones.menuListasPrecio,
      title: "Listas de precios",
      type: "item",
      url: "/listaDePrecios",
      icon: icons.DollarCircleOutlined,
      breadcrumbs: false,
      admitedRoles: gruposDeRoles.level3,
      optionId: opciones.menuListasPrecio,
      isWelcomeRoute: false,
    },
    {
      id: opciones.menuRentabilidad,
      title: "Rentabilidad",
      type: "item",
      url: "/rentabilidad",
      icon: icons.DollarCircleOutlined,
      breadcrumbs: false,
      admitedRoles: gruposDeRoles.level3,
      optionId: opciones.menuRentabilidad,
      isWelcomeRoute: false,
    },
    // {
    //   id: 23,
    //   title: "Formulas Base",
    //   type: "item",
    //   url: "/formulasBase",
    //   icon: icons.FunctionOutlined,
    // optionId:1,
    //       isWelcomeRoute:false,breadcrumbs: false,
    //   admitedRoles: gruposDeRoles.todos,
    // },
    // {
    //   id: 24,
    //   title: "Formulas Pigmentos",
    //   type: "item",
    //   url: "/formulasPigmentos",
    //   icon: icons.FunctionOutlined,
    // optionId:1,
    //       isWelcomeRoute:false,breadcrumbs: false,
    //   admitedRoles: gruposDeRoles.todos,
    // },

    {
      id: opciones.menuMateriales,
      title: "Materiales",
      type: "item",
      url: "/materiales",
      icon: icons.BuildOutlined,
      breadcrumbs: false,
      admitedRoles: gruposDeRoles.level4,
      optionId: opciones.menuMateriales,
      isWelcomeRoute: false,
    },
    {
      id: opciones.menuTipoMaterial,
      title: "Tipo Material",
      type: "item",
      url: "/materialTipo",
      icon: icons.BuildOutlined,
      breadcrumbs: false,
      admitedRoles: gruposDeRoles.level2,
      optionId: opciones.menuTipoMaterial,
      isWelcomeRoute: false,
    },

    {
      id: opciones.menuUsuarios,
      title: "Usuarios",
      type: "item",
      url: "/users",
      icon: icons.UserOutlined,
      breadcrumbs: false,
      admitedRoles: gruposDeRoles.level3,
      optionId: opciones.menuUsuarios,
      isWelcomeRoute: false,
    },
    {
      id: opciones.menuTipoUsuario,
      title: "Tipo usuario",
      type: "item",
      url: "/userType",
      icon: icons.UserOutlined,
      breadcrumbs: false,
      admitedRoles: gruposDeRoles.level3,
      optionId: opciones.menuTipoUsuario,
      isWelcomeRoute: false,
    },
    {
      id: opciones.menuRoles,
      title: "Roles",
      type: "item",
      url: "/roles",
      icon: icons.ContactsOutlined,
      breadcrumbs: false,
      admitedRoles: gruposDeRoles.level1,
      optionId: opciones.menuRoles,
      isWelcomeRoute: false,
    },

    // {
    //   id: 31,
    //   title: "Rol Opciones",
    //   type: "item",
    //   url: "/rolOptions",
    //   icon: icons.ContactsOutlined,
    // optionId:1,
    //       isWelcomeRoute:false,breadcrumbs: false,
    //   admitedRoles: gruposDeRoles.todos,
    // },
    {
      id: opciones.menuOpciones,
      title: "Opciones",
      type: "item",
      url: "/opciones",
      icon: icons.MenuOutlined,
      breadcrumbs: false,
      admitedRoles: gruposDeRoles.level1,
      optionId: opciones.menuOpciones,
      isWelcomeRoute: false,
    },
    {
      id: opciones.menuAuditoria,
      title: "Auditoria",
      type: "item",
      url: "/audits",
      icon: icons.BarChartOutlined,
      breadcrumbs: false,
      admitedRoles: gruposDeRoles.level1,
      optionId: opciones.menuAuditoria,
      isWelcomeRoute: false,
    },
    {
      id: opciones.menuSync,
      title: "Sync",
      type: "item",
      url: "/sync",
      icon: icons.CloudSyncOutlined,
      breadcrumbs: false,
      admitedRoles: gruposDeRoles.level3,
      optionId: opciones.menuSync,
      isWelcomeRoute: false,
    },
    {
      id: opciones.menuContenido,
      title: "Contenido",
      type: "item",
      url: "/contenido",
      icon: icons.CloudSyncOutlined,
      breadcrumbs: false,
      admitedRoles: gruposDeRoles.level3,
      optionId: opciones.menuContenido,
      isWelcomeRoute: false,
    },
  ],
};

export default dashboard;
