export enum books {
  CLASICOS = 2,
  BARNICES_Y_LACAS = 3,
  VANGUARDIA = 4,
  FERROMICACEO = 5,
  TERSPLAST = 6,
  PISCINAS = 7,
  INDULAC = 8,
  PROPIOS = 1,
  EL_COLOR_ESTA_EN_VOS = 9,
}

// export enum books {
//   CLASICOS = 1,
//   BARNICES_Y_LACAS = 2,
//   VANGUARDIA = 3,
//   FERROMICACEO = 4,
//   TERSPLAST = 5,
//   PISCINAS = 6,
//   INDULAC = 7,
//   PROPIOS = 8,
// }

export const booksByUserType = {
  Administrador: [
    books.CLASICOS,
    books.BARNICES_Y_LACAS,
    books.VANGUARDIA,
    books.FERROMICACEO,
    books.TERSPLAST,
    books.PISCINAS,
    books.INDULAC,
  ],
  Colorin: [books.CLASICOS, books.VANGUARDIA],
  Tersuave: [books.BARNICES_Y_LACAS, books.PISCINAS],
  Industria: [books.FERROMICACEO, books.TERSPLAST, books.INDULAC],
};
